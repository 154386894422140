import React, { useState } from "react";
import CommonButton from "../../CommonButton";
import useStyles from "../styles";
import RupeeIcon from '../../../../assets/images/rupees.png'

const CodButton = ({
  handleCreateOrder,
  handleCreatePayment,
  paymentGateways,
  onPaymentSuccess,
  amountToPay
}) => {
  const classes = useStyles();
  const [paymentLoading, setPaymentLoading] = useState(false);

  const currentGateway = paymentGateways?.find(
    (item) => item?.internal_name === "cod"
  );

  const handlePayCod = async () => {
    setPaymentLoading(true);

    try {
      const response = await handleCreateOrder();
      if (response?.order) {
        let orderResponse = await handleCreatePayment(response, currentGateway);
  
        if (orderResponse?.order?.order_id) {
          onPaymentSuccess(orderResponse?.order?.order_id, {'status': 'success'}, orderResponse);
        }
      }
    } catch (error) {
      console.log('error::', error);
    } finally {
      setPaymentLoading(false);
    }
  };

  return (
    <CommonButton
      classProp={`${classes.proceedButton} codButton`}
      onClick={handlePayCod}
      loading={paymentLoading}
      startIcon={<img src={RupeeIcon} alt="COD" />}
      gtmId="codButton"
    >
      Cash on delivery - ₹{amountToPay}
    </CommonButton>
  );
};

export default CodButton;
