import * as CouponApi from '../api/coupons';
import { createAction } from 'redux-actions';

import { GET_COUPONS_FAILURE, GET_COUPONS_SUCCESS } from '../constants/actionTypes';

const getCouponSuccess = createAction(GET_COUPONS_SUCCESS);
const getCouponFailure = createAction(GET_COUPONS_FAILURE);

export function getCoupons() {
  return dispatch => CouponApi.getCoupon().then(({ data }) => {
    dispatch(getCouponSuccess({ data }));
  }).catch(error => {
    dispatch(getCouponFailure(error));
  })
}

