import React, { useEffect } from "react";
import CategoryList from "../Common/CategoryList";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ProductFilters from "./ProductFilters";
import { Box, SwipeableDrawer } from "@material-ui/core";
import useStyles from "./styles";
import { Sort } from "@material-ui/icons";

const Sidebar = ({ activeProduct, heading }) => {
  const categories = useSelector((state) => state.categories.mainCategories);
  const { categoryId, productId } = useParams();
  const classes = useStyles();

  const [isOpened, setIsOpened] = React.useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsOpened((prev) => (prev ? false : false));
  }, [location]);

  return (
    <div>
      {/* Showing in mobile */}
      <div className={classes.mobileSidebar}>
        <Box className={classes.mobHeadingContainer}>
          <Box mt={.7}>{heading}</Box>

          {!productId && (
            <Box className={classes.filterIcon} onClick={() => setIsOpened(true)}>
              Filters <Sort style={{ fontSize: 18 }} />
            </Box>
          )}
        </Box>

        <SwipeableDrawer
          anchor={"right"}
          open={isOpened}
          onClose={() => setIsOpened(false)}
          onOpen={() => setIsOpened(true)}
          style={{zIndex: 999999999}}
        >
          <Box pl={2} pb={4}>
            <CategoryList
              categories={categories}
              activeCategoryId={categoryId || activeProduct?.category_id}
            />

            <ProductFilters />
          </Box>
        </SwipeableDrawer>
      </div>

      {/* Showing in website or big screen device */}
      <div className={classes.webSidebar}>
        <CategoryList
          categories={categories}
          activeCategoryId={categoryId || activeProduct?.category_id}
        />

        <ProductFilters />
      </div>
    </div>
  );
};

export default Sidebar;
