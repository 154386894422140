import React, { useState } from "react";
import "../style.css";
import { useCallback } from "react";
import useRazorpay from "react-razorpay";

const Razorpay = ({
  children,
  createPayment,
  onPaymentSuccess,
  onPaymentFailed,
  icon
}) => {
  const [RazorpayCheckout] = useRazorpay();

  const onPaymentFailure = useCallback(
    (response) => {
      onPaymentFailed({
        reason:
          response?.error?.description ?? "Payment Failed. Please try again.",
      });
    },
    [onPaymentFailed]
  );

  const [isLoading, setIsLoading] = useState(false);

  const payNow = useCallback(async () => {
    try {
      setIsLoading(true);
      let orderResponse = await createPayment();

      setIsLoading(false);
      if (orderResponse?.order) {
        if (orderResponse?.order?.order_error_message) {
          onPaymentFailed({
            reason: orderResponse?.order?.order_error_message,
          });
        } else {
          const options = {
            key: orderResponse?.settings?.api_details?.api_key,
            amount: orderResponse?.order.total_amount * 100,
            currency: orderResponse?.order?.currency_code ?? "INR",
            name: "Homeshop Fashion",
            order_id: orderResponse?.order?.payment_gateway_order_id,
            image: `https://homeshopfashion.com/backend/data/assets/1724178260-razorpay-logo.png`,
            theme: {
              color: "#E16434",
            },
            prefill: {
              name: orderResponse?.customer?.name,
              contact: orderResponse?.customer?.mobile_number,
            },
            notes: {
              address: orderResponse?.customer?.address,
            },
            handler: (response) =>
              onPaymentSuccess(orderResponse?.order?.order_id, response),
          };
          const rzr = new RazorpayCheckout(options);
          rzr.on("payment.failed", (errResponse) =>
            onPaymentFailure(errResponse)
          );
          rzr.open();
        }
      } else {
        throw new Error("Something went wrong. Please try again.");
      }
    } catch (e) {
      onPaymentFailed({
        reason: e.message,
      });
    }
  }, [
    RazorpayCheckout,
    createPayment,
    onPaymentFailed,
    onPaymentFailure,
    onPaymentSuccess,
  ]);

  let button = <button className='razarpayButton payNowButton cartCheckoutBuy' data-gtm-id={"cartCheckoutBuy"}>{icon} Buy Now</button>;

  if (children) {
    button = children;
  }

  if (isLoading) {
    button = (
      <button className='razarpayButton payNowButton cartCheckoutBuy loadingButton'>
        Buy Now
        <div className='spinner'>
          <span />
        </div>
      </button>
    );
  }

  return (
    <div className='payNowButtonContainer' onClick={(e) => payNow()}>
      {button}
    </div>
  );
};

export default Razorpay;
