import React, { useState } from "react";
import CommonButton from "../CommonButton";
import useStyles from "./styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCartTwoTone";
import CheckoutPopup from "../CheckoutPopup";
import { useDispatch } from "react-redux";
import { showErrorSnackbar } from "../../../actions/snackbar";
import { BUY_NOW_CART_UPDATED } from "../../../constants/actionTypes";
import { getFinalPriceAmount } from "../../../helpers/common";

const BuyNowButton = ({
  cartData,
  product,
  quantity = 1,
  fullWidth = false,
}) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  const handleBuyNow = () => {
    const minimumOrderQuantity = product?.minimum_order_quantity;
    const maximumOrderQuantity = product?.maximum_order_quantity;

    if (quantity > maximumOrderQuantity) {
      dispatch(
        showErrorSnackbar(
          `You can not order more than ${maximumOrderQuantity} item(s)`,
          null
        )
      );
      return;
    }

    if (minimumOrderQuantity > quantity) {
      dispatch(
        showErrorSnackbar(
          `You must order at least ${minimumOrderQuantity} item(s)`,
          null
        )
      );
      return;
    }

    dispatch({
      type: BUY_NOW_CART_UPDATED,
      payload: [{ ...product, qty: quantity }],
    });

    setOpenModal(true);
  };

  const finalPrice = getFinalPriceAmount(product);

  if (product?.available_stock <= 0 || finalPrice === 0) {
    return null
  }

  return (
    <>
      <CommonButton
        rounded
        style={{
          borderRadius: 28,
          minWidth: 190,
          width: fullWidth ? "100%" : null,
        }}
        classProp={`${classes.commonButton} buyNowButton shining-flash-button`}
        onClick={handleBuyNow}
        startIcon={<ShoppingCartIcon />}
        gtmId="buyNowButton"
      >
        Order Now
      </CommonButton>

      <CheckoutPopup
        openModal={openModal}
        handleClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default BuyNowButton;
