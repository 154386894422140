import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  resendBlock: {
    position: "relative",
    width: "100%",
    textAlign: "center",
  },
  circularProgress: {
    marginTop: 20,
  },
  heading: {
    fontSize: 24,
    fontWeight: 700,
    color: "#666",
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
  },
}));

export default useStyles;
