import { CircularProgress } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";
import classnames from "classnames";

const CommonButton = ({
  children,
  width,
  height,
  variant = "contained",
  classProp,
  rounded = true,
  style,
  loading,
  customClass = "",
  startIcon,
  endIcon,
  disabled,
  gtmId = "",
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={`${classes.wrapper}`}>
        <button
          className={classnames(
            variant === "outlined" ? classes.outlineButton : "",
            classes.button,
            classProp,
            customClass,
            disabled || loading ? classes.disabledButton : ""
          )}
          style={{
            cursor: "pointer",
            width,
            height,
            borderRadius: rounded ? 20 : 3,
            ...style,
          }}
          {...props}
          data-gtm-id={gtmId}
        >
          {startIcon && (
            <span
              style={{ lineHeight: 0.5 }}
              onClick={(e) => e.stopPropagation()}
            >
              {startIcon}
            </span>
          )}
          
          {children}

          {startIcon && (
            <span
              style={{ lineHeight: 0.5 }}
              onClick={(e) => e.stopPropagation()}
            >
              {endIcon}
            </span>
          )}
        </button>

        {loading && (
          <CircularProgress
            size={26}
            color='primary'
            className={classes.buttonProgress}
          />
        )}
      </div>
    </>
  );
};

export default CommonButton;
