import React from "react";
import CustomHeading from "../../Common/CustomHeadings";
import useStyles from "./styles";
import {
  getAppliedFilterByName,
  getSettingByKey,
  toKeyValues,
} from "../../../helpers/common";
import { useSelector } from "react-redux";
import { Collapse } from "@material-ui/core";

const FilterByColor = ({ onFilterChange, availableFilters }) => {
  const settings = useSelector((state) => state.contents.frontendData);
  const filtersColorsStr = getSettingByKey(settings, "filter_colors")?.value;
  const colorData = toKeyValues(filtersColorsStr);

  const filteredColorData = colorData?.filter((color) =>
    availableFilters?.find(
      (item) => item?.item_color?.toLowerCase() === color?.value?.toLowerCase()
    )
  );

  const classes = useStyles();

  const activeColor = getAppliedFilterByName("color");

  const getActiveBorderColor = (item) => {
    if (activeColor === item?.value) {
      if (item?.value?.toLowerCase() === "white") {
        return "#ccc";
      }
      return item?.key;
    }

    return "";
  };

  const [open, setOpen] = React.useState(false);

  if (filteredColorData?.length === 0) {
    return null;
  }

  return (
    <div>
      <CustomHeading
        text='Filter by Color'
        mb={3}
        showRightIcon={true}
        isCollaped={!open}
        onClick={() => setOpen(!open)}
      />

      <Collapse in={open}>
        {filteredColorData?.map((item, index) => (
          <div
            key={`color-${item?.value}-${index}`}
            className={classes.filterItem}
            onClick={() => onFilterChange("color", item?.value)}
          >
            <div
              className={classes.colorBoxWrapper}
              style={{
                borderColor: getActiveBorderColor(item),
              }}
            >
              <div
                className={`${classes.colorBox} ${classes.colorBoxActive}`}
                style={{ backgroundColor: item?.key }}
              />
            </div>
            <p>{item?.value}</p>
          </div>
        ))}
      </Collapse>
    </div>
  );
};

export default FilterByColor;
