import { Box, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getBanners } from "../../actions/banners";
import { getCategory } from "../../actions/categories";
import { getContents } from "../../actions/contents";
import { getProducts } from "../../actions/products";
import { LOAD_CART_DATA } from "../../constants/actionTypes";
import { convertToSlug, imageUrl } from "../../helpers/common";
import CategoryCard from "../Common/CategoryCard";
import CommonButton from "../Common/CommonButton";
import MetaTags from "../Common/MetaTags";
import ProductBox from "../Common/ProductBox";
import FeaturedProductCarousel from "./FeaturedProductsCarousel";
import HeaderCarousel from "./HeaderCarousel";
import useStyles from "./styles";
import FlashSaleProductsCarousel from "./FlashSaleProductsCarousel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Heading from "../../helpers/Heading";

function Home() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.mainCategories);
  const featuredProductSize = useSelector(
    (state) => state.products.featured
  ).length;
  const sellingProducts = useSelector((state) => state.products.selling);

  const flashSaleProducts = useSelector(
    (state) => state.products.flashSale
  ).length;

  useEffect(() => {
    dispatch(getCategory());
    dispatch(getProducts({ product_labels: "Hot" }, 12, 1));
    dispatch(getProducts({ product_labels: "Offer" }), 10, 1);
    dispatch(getProducts({ product_labels: "Featured" }), 10, 1);
    dispatch(getProducts({ product_labels: "Flash Sale" }), 10, 1);
    dispatch(getBanners("Homepage Banners"));
    dispatch(getBanners("Homepage Mobile Banners"));
    dispatch(getContents("testimonials"));
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: LOAD_CART_DATA });
  }, [dispatch]);

  return (
    <Box className={classes.root}>
      <HeaderCarousel />
      <Box textAlign='center' className={classes.catogoriesList}>
        <Heading>Categories</Heading>

        <Grid
          container
          spacing={4}
          justifyContent='center'
          className={classes.gridList}
        >
          {categories
            .filter(
              (category) =>
                category.parent_id !== 0 && category.total_sub_categories === 0
            )
            .map((category, index) => (
              <Grid item lg={3} md={4} sm={4} xs={6} key={index} className={classes.gridBox}>
                <Link
                  to={`/category/${category.id}/1/${convertToSlug(
                    category.name
                  )}`}
                  className="categoryBoxMainPage"
                >
                  {" "}
                  <CategoryCard
                    image={imageUrl(category.image, "categories", "430x430-")}
                    title={category.name}
                    width={320}
                    square
                  />
                </Link>
              </Grid>
            ))}
        </Grid>
      </Box>

      {flashSaleProducts !== 0 && (
        <Box textAlign='center' p={5} className={classes.featuredCarousel}>
          <Box mb={3.5}>
            <Heading>Flash Sale</Heading>
          </Box>

          <FlashSaleProductsCarousel />
        </Box>
      )}

      {sellingProducts.length !== 0 && (
        <Box textAlign='center' className={classes.hotProductsBox}>
          <Box mb={2}>
            <Heading>Hot Products</Heading>
          </Box>

          <Grid container spacing={4} className={classes.gridList}>
            {sellingProducts?.map((product) => (
              <Grid item key={product.id} md={3}>
                <Box display='flex' justifyContent='center'>
                  {product.main_image && (
                    <Link
                      to={`/product/${product.id}/1/${convertToSlug(
                        product.name
                      )}`}
                      className={classes.link}
                    >
                      <ProductBox
                        image={imageUrl(
                          product.main_image,
                          "products/" + product.id,
                          "600x720-"
                        )}
                        title={product.name}
                        price={product.mrp}
                        discountedPrice={product.selling_price}
                        product={product}
                      />
                    </Link>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box style={{textAlign: 'center', display: 'inline-block'}}>
            <CommonButton
              style={{
                marginTop: 25,
                backgroundColor: "#295681",
                height: 53,
                padding: "8px 30px",
                borderRadius: 28,
              }}
              endIcon={<ArrowForwardIcon />}
            >
              <Link to='/products/hot-products/1' className={classes.link}>
                View All Hot Products
              </Link>
            </CommonButton>
          </Box>
        </Box>
      )}

      {featuredProductSize !== 0 && (
        <Box textAlign='center' p={5} className={classes.featuredCarousel}>
          <Box mb={4}>
            <Heading>Featured Products</Heading>
          </Box>
          <FeaturedProductCarousel />
        </Box>
      )}

      {/* <FooterCarousel /> */}

      <MetaTags
        module='home'
        title='Welcome'
        image=''
        description={`Discover the latest trends in women's fashion at HomeShop Fashion!`}
      />
    </Box>
  );
}

export default Home;
