import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  filterItem: {
    padding: "6px 0",
    cursor: "pointer",
    // textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    gap: 10,
    "& p": {
      margin: 0,
      fontSize: 13,
    },
  },
  colorBox: {
    width: 14,
    height: 14,
    borderRadius: 50,
    border: "1px solid #ccc",
  },
  colorBoxWrapper: {
    width: 20,
    height: 20,
    border: "1px solid transparent",
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  radioGroup: {
    "& span": {
      fontSize: 13,
    },
  },
  radio: {
    "& svg": {
      fontSize: 17,
    },
  },
}));

export default useStyles;
