import { Box } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

const Heading = ({ ...props }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.heading} {...props}>
        {props.children}
      </Box>
      <Box className={classes.dash} display='flex' margin='auto' />
    </>
  );
};

export default Heading;
