import { Box } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import PaymentSec from "./PaymentSec";

const CheckoutDetailsDisplay = ({ customer, isMobile }) => {
  const profile = useSelector((state) => state.auth.profile);

  return (
    <Box>
      <Box style={{ background: "#f1eeee", padding: 15, borderRadius: 15 }}>
        {profile.email && (
          <Box display='flex' my={2} fontSize={16}>
            <Box fontWeight={700} mr={2}>
              Email:
            </Box>
            <Box color='#666'>{profile.email}</Box>
          </Box>
        )}

        <Box display='flex' my={2} fontSize={16}>
          <Box fontWeight={700} mr={2}>
            Name:
          </Box>
          <Box color='#666'>{customer.name}</Box>
        </Box>

        <Box display='flex' my={2} fontSize={16}>
          <Box fontWeight={700} mr={2}>
            Address:
          </Box>
          <Box color='#666'>{customer.address}</Box>
        </Box>

        <Box display='flex' my={2} fontSize={16}>
          <Box fontWeight={700} mr={2}>
            City:
          </Box>
          <Box color='#666'>{customer.city}</Box>
        </Box>

        <Box display='flex' my={2} fontSize={16}>
          <Box fontWeight={700} mr={2}>
            Country:
          </Box>
          <Box color='#666'>{customer.country}</Box>
        </Box>

        <Box display='flex' my={2} fontSize={16}>
          <Box fontWeight={700} mr={2}>
            Pin Code:
          </Box>
          <Box color='#666'>{customer.pin_code}</Box>
        </Box>

        <Box display='flex' my={2} fontSize={16}>
          <Box fontWeight={700} mr={2}>
            Mobile:
          </Box>
          <Box color='#666'>{customer.mobile_number}</Box>
        </Box>
      </Box>

      {/* This payment sec will show only on web */}
      {!isMobile && <PaymentSec />}
    </Box>
  );
};

export default CheckoutDetailsDisplay;
