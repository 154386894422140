import * as AssetsApi from '../api/assets';
import { createAction } from 'redux-actions';
import { GET_ASSETS_FAILURE, GET_ASSETS_SUCCESS } from '../constants/actionTypes';

const getAssetsSuccess = createAction(GET_ASSETS_SUCCESS);
const getAssetsFailure = createAction(GET_ASSETS_FAILURE);

export function getAssets(params) {
  return dispatch => AssetsApi.getAssets(params).then(({ data }) => {
    dispatch(getAssetsSuccess({ data }));
  }).catch(error => {
    dispatch(getAssetsFailure(error));
  })
}

