import { Box, Grid } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getFinalPriceAmount,
  getSetting,
  imageUrl,
} from "../../../helpers/common";
import ApplyCoupons from "../../Shipping/ApplyCoupons";
import useStyles from "./styles";

export default function ProductSummary({
  deliveryCharges = 0,
  appliedCouponValue,
  setAppliedCouponValue,
  orderData,
  pageSource = "shipping",
  setAmountToPay,
  ...props
}) {
  const classes = useStyles();
  const cartProducts = useSelector((state) => state.cart.buy_now_cart_data);
  const frontendSettings = useSelector((state) => state.contents?.frontendData);
  const showFreeDelivery = getSetting(
    "show_free_delivery",
    frontendSettings
  )?.value;

  const cartDiscount = localStorage.getItem("hs_coupon_discount");
  const parsedCartDiscount = JSON.parse(cartDiscount);
  const discountedProducts = parsedCartDiscount?.data;

  useEffect(() => {
    if (parsedCartDiscount && setAppliedCouponValue) {
      setAppliedCouponValue(parsedCartDiscount?.applied_coupon_code);
    }
  }, [parsedCartDiscount, setAppliedCouponValue]);

  const getTotalDiscount = useCallback(() => {
    return (
      discountedProducts?.reduce(
        (totalDiscount, item) => totalDiscount + item.discount,
        0
      ) || 0
    );
  }, [discountedProducts]);

  const getTotal = useCallback(() => {
    return cartProducts?.reduce(
      (acc, product) => acc + getFinalPriceAmount(product) * product.qty,
      0
    );
  }, [cartProducts]);

  const hasFreeDelivery =
    showFreeDelivery === "yes" || Number(deliveryCharges) === 0;

  const getDeliveryFee = () => {
    if (hasFreeDelivery) {
      return <Box color='#1eab5c'>Free</Box>;
    }

    if (pageSource === "shipping") {
      return (
        <Box fontSize={14}>
          (Delivery fee will be calculated on the payment page)
        </Box>
      );
    }

    return <> &#x20b9; {deliveryCharges} </>;
  };

  const getFinalPrice = (product) => {
    // cut mrp and selling price and show only offer price
    if (parseFloat(product.offer_price) > 0) {
      return (
        <Box width='100%'>
          <Box display='flex' width='100%' gridGap={20} pt={1}>
            <Box
              fontSize={13}
              color='#666'
              style={{ textDecoration: "line-through" }}
            >
              MRP: &#x20b9;{product.mrp}
            </Box>
            <Box
              fontSize={13}
              color='#666'
              style={{ textDecoration: "line-through" }}
            >
              Selling Price: &#x20b9;{product.selling_price}
            </Box>
          </Box>
          <Box fontSize={15} pt={0.5} style={{ color: "green" }}>
            Offer Price: &#x20b9;{product.offer_price}
          </Box>
        </Box>
      );
    }

    if (parseFloat(product.selling_price) > 0) {
      return (
        <Box
          display='flex'
          alignItems='center'
          width='100%'
          mt={1}
          gridGap={30}
        >
          <Box
            fontSize={13}
            color='#666'
            style={{ textDecoration: "line-through" }}
          >
            MRP: &#x20b9;{product.mrp}
          </Box>
          <Box fontSize={15} style={{ color: "green" }}>
            Selling Price: &#x20b9;{product.selling_price}
          </Box>
        </Box>
      );
    }

    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        width='100%'
        mt={1}
      >
        <Box fontSize={17} style={{ color: "green" }}>
          Price: &#x20b9;{product.mrp}
        </Box>
      </Box>
    );
  };

  const getTotalAmount = useCallback(() => {
    if (orderData?.total_amount) {
      return orderData?.total_amount?.toFixed(2);
    }

    return (getTotal() + deliveryCharges - getTotalDiscount()).toFixed(2);
  }, [deliveryCharges, getTotal, getTotalDiscount, orderData?.total_amount]);

  useEffect(() => {
    setAmountToPay(getTotalAmount());
  }, [getTotalAmount, setAmountToPay]);

  return (
    <Grid item className={classes.items}>
      <Box mb={2}>
        {cartProducts.map((product) => {
          const couponDiscount = discountedProducts?.find(
            (item) => item?.item_id === product?.id
          );

          return (
            <Box key={product.id} className={classes.prodBox}>
              <Box className={classes.prodImgBox}>
                <img
                  className={classes.images}
                  src={imageUrl(product.main_image, "products/" + product.id)}
                  alt='cart-item'
                />
              </Box>

              <Box flex={1} ml={2}>
                <Box className={classes.prodName}>{product.name}</Box>
                <Box
                  display='flex'
                  alignItems='center'
                  gridGap={10}
                  color='#4f4f4f'
                  flexWrap='wrap'
                  mt={1}
                  mb={1}
                >
                  <Box className={classes.pill}>Size: {product?.item_size}</Box>
                  <Box className={classes.pill}>
                    Color: {product?.item_color}
                  </Box>

                  <Box className={classes.pill}>Quantity: {product.qty}</Box>
                </Box>

                {getFinalPrice(product)}
                {couponDiscount?.discount > 0 && (
                  <Box color='#1eab5c' mt={0.3} fontSize={14}>
                    Coupon Discount: ₹{couponDiscount?.discount}
                  </Box>
                )}
              </Box>

              <Box className={classes.prodAmount}>
                &#x20b9;{getFinalPriceAmount(product) * product.qty}
              </Box>
            </Box>
          );
        })}
      </Box>

      <ApplyCoupons
        appliedCouponValue={appliedCouponValue}
        setAppliedCouponValue={setAppliedCouponValue}
        cartProducts={cartProducts}
      />
      {/* We are using this children props only on checkout page */}
      {props.children}

      <Box display='flex' my={2} justifyContent='space-between' fontSize={16}>
        <Box color='#666'>Subtotal</Box>
        <Box fontWeight={700}>&#x20b9;{getTotal()}</Box>
      </Box>

      <Box
        display='flex'
        my={2}
        justifyContent='space-between'
        fontSize={16}
        gridGap={50}
      >
        <Box color='#666' minWidth={130}>
          Delivery Fee
        </Box>
        <Box fontWeight={500}>{getDeliveryFee()}</Box>
      </Box>

      {getTotalDiscount() ? (
        <Box display='flex' my={2} justifyContent='space-between' fontSize={16}>
          <Box color='#666'>
            Coupon Discount{" "}
            {orderData?.coupon_code && (
              <span className={classes.appliedCoupon}>
                ({orderData?.coupon_code})
              </span>
            )}{" "}
          </Box>
          <Box fontWeight={500} color='#1eab5c'>
            -&#x20b9;
            {orderData?.coupon_discount_amount.toFixed(0) || getTotalDiscount()}
          </Box>
        </Box>
      ) : null}

      <div style={{ borderBottom: "1px solid #e1e1e1" }}></div>

      <Box display='flex' my={2} justifyContent='space-between' fontSize={18}>
        <Box fontWeight={700}>Total Amount</Box>
        <Box fontWeight={700} color='#E16434'>
          &#x20b9; {getTotalAmount()}
        </Box>
      </Box>
    </Grid>
  );
}
