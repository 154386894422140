import React from "react";
import useStyles from "./styles";
import { Box } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  capitalize,
  getFilterPath,
  removeUrlParam,
} from "../../helpers/common";

const AppliedFilters = () => {
  const classes = useStyles();
  const location = useLocation();
  const { categoryId, categoryName } = useParams();
  const navigate = useNavigate();

  // Function to get query parameters as an object
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const queryParams = {};

    for (let [key, value] of params.entries()) {
      queryParams[key] = value;
    }

    return queryParams;
  };

  const filters = getQueryParams();

  const removeFilter = (filterName) => {
    const params = removeUrlParam(filterName);
    let pageNumber = 1;
    let path = getFilterPath(categoryId, categoryName, pageNumber, params);
    navigate(path, { replace: true });
  };


  if (!Object.keys(filters)?.length) {
    return null
  }

  return (
    <div className={classes.filterBar}>
      <Box>Filters:</Box>

      <Box className={classes.filterPillWrapper}>
        {Object.keys(filters)?.map((key) => (
          <Box className={classes.filterPill} onClick={() => removeFilter(key)}>
            <b>{capitalize(key)}</b> - {filters[key]}
            <ClearIcon style={{ fontSize: 14, marginTop: -2 }} />
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default AppliedFilters;
