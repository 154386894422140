import { Box, Grid, TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import PaymentButtons from "./PaymentButtons";
import { showErrorSnackbar } from "../../../actions/snackbar";
import { guestLogin } from "../../../api/customer";
import {
  authKey_guest_local_storage_key,
  userInfo_guest_local_storage_key,
} from "../../../helpers/common";

const ShippingForm = ({
  appliedCoupon,
  amountToPay,
  handleCheckoutSuccess,
}) => {
  const classes = useStyles();
  const profile = useSelector((state) => state.auth.profile);
  const [isGuestLoading, setIsGuestLoading] = useState(false);
  const guestAuthToken = localStorage.getItem(authKey_guest_local_storage_key)

  const [customer, setCustomer] = useState({
    name: "",
    address: "",
    city: "",
    country: "India",
    state: "",
    pin_code: "",
    mobile_number: "",
  });

  useEffect(() => {
    if (profile) {
      const guestUserInfo = localStorage.getItem(
        userInfo_guest_local_storage_key
      );
      const guestUser = guestUserInfo ? JSON.parse(guestUserInfo) : null;

      setCustomer((currVal) => {
        return {
          ...currVal,
          address: profile?.address || guestUser?.address || "",
          name: profile?.name || guestUser?.name || "",
          mobile_number:
            profile?.mobile_number ||
            guestUser?.mobile_number?.slice(0, 10) ||
            "",
          pin_code: profile?.pin_code || guestUser?.pin_code || "",
          city: profile?.city || guestUser?.city || "",
          email: profile?.email || guestUser?.email || "",
          district: profile?.district || guestUser?.district || "",
          state: profile?.state || guestUser?.state || "",
        };
      });
    }
  }, [profile]);


  const dispatch = useDispatch();

  const handleValidation = () => {
    // if (!customer?.mobile_number || !/^\d{10}$/.test(customer.mobile_number)) {
    if (!customer?.mobile_number || customer?.mobile_number?.length !== 10) {
      dispatch(
        showErrorSnackbar("Please enter a valid 10-digit mobile number")
      );
      return false;
    }

    if (!customer?.name) {
      dispatch(showErrorSnackbar("Please enter your name"));
      return false;
    }

    if (!customer?.address) {
      dispatch(showErrorSnackbar("Please enter a shipping address"));
      return false;
    }

    if (!customer?.city) {
      dispatch(showErrorSnackbar("Please enter a shipping city"));
      return false;
    }

    if (!customer?.country) {
      dispatch(showErrorSnackbar("Please select a country"));
      return false;
    }

    if (!customer?.state) {
      dispatch(showErrorSnackbar("Please select a state"));
      return false;
    }

    if (!customer?.pin_code || !/^\d{6}$/.test(customer.pin_code)) {
      dispatch(showErrorSnackbar("Please enter a valid 6-digit pin code"));
      return false;
    }

    return true; // All validations passed
  };

  const handleGuestLogin = useCallback(() => {
    if (profile?.id) {
      return;
    }

    if (customer?.mobile_number && customer?.mobile_number?.length === 10) {
      setIsGuestLoading(true);

      guestLogin({ mobile_number: customer.mobile_number })
        ?.then((res) => {
          if (res?.data?.token) {
            localStorage.setItem(
              authKey_guest_local_storage_key,
              res?.data?.token
            );
          }
        })
        .catch((err) => {
          console.log("guest login err::", err);
        })
        .finally(() => {
          setIsGuestLoading(false);
        });
    }
  }, [customer.mobile_number, profile?.id]);


  useEffect(() => {
    if (!profile?.id && !guestAuthToken )  {
      handleGuestLogin()
    }
  }, [guestAuthToken, handleGuestLogin, profile?.id])

  const onChange = (e) => {
    if (e.target.name === "mobile_number" && e.target.value?.length > 10) {
      setCustomer({ ...customer, [e.target.name]: e.target.value?.slice(1, 11) });
    } else {
      setCustomer({ ...customer, [e.target.name]: e.target.value });
    }

  };

  return (
    <div>
      <h2 style={{ textAlign: "center", marginTop: 35 }}>
        Enter your shipping address
      </h2>

      <TextField
        onChange={onChange}
        fullWidth
        variant='outlined'
        value={customer?.mobile_number || ""}
        name='mobile_number'
        label='Mobile'
        className={classes.shippingField}
        type='number'
        onBlur={handleGuestLogin}
      />

      <TextField
        required
        fullWidth
        variant='outlined'
        value={customer.name}
        name='name'
        onChange={onChange}
        label='Your Name'
        className={classes.shippingField}
      />
      <TextField
        required
        fullWidth
        variant='outlined'
        value={customer.address}
        name='address'
        onChange={onChange}
        label='Shipping Address'
        className={classes.shippingField}
      />
      <TextField
        required
        fullWidth
        variant='outlined'
        value={customer.city}
        name='city'
        onChange={onChange}
        label='Shipping City'
        className={classes.shippingField}
      />
      <Box my={1}>
        <Grid container spacing={3}>
          <Grid item sm={4} xs={12}>
            <CountryDropdown
              value={customer.country}
              onChange={(val) => setCustomer({ ...customer, country: val })}
              className={classes.regions}
            />
          </Grid>
  
          <Grid item sm={4} xs={12}>
            <RegionDropdown
              country={customer.country}
              value={customer.state || "Select a State"}
              onChange={(val) => setCustomer({ ...customer, state: val })}
              className={classes.regions}
              blankOptionLabel='Select State'
              defaultOptionLabel='Select State'
            />
          </Grid>
  
          <Grid item sm={4} xs={12}>
            <TextField
              required
              variant='outlined'
              label='Pin code'
              name='pin_code'
              placeholder='Your Pin code'
              value={customer.pin_code}
              inputProps={{ length: 6 }}
              onChange={onChange}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </Box>

      <TextField
        onChange={onChange}
        fullWidth
        variant='outlined'
        value={customer?.email || ""}
        name='email'
        label='Email (Optional)'
        className={classes.shippingField}
      />

      <PaymentButtons
        appliedCoupon={appliedCoupon}
        customer={customer}
        handleValidation={handleValidation}
        amountToPay={amountToPay}
        handleCheckoutSuccess={handleCheckoutSuccess}
        isGuestLoading={isGuestLoading}
      />
    </div>
  );
};

export default ShippingForm;
