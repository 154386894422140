import { GET_COUPONS_SUCCESS, GET_COUPONS_FAILURE } from '../constants/actionTypes';

const INITIAL_STATE = { data: [] };

export default function coupons(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COUPONS_SUCCESS:
      return { ...state, data: action.payload.data };
    case GET_COUPONS_FAILURE:
      return { ...state, data: [] };
    default:
      return state;
  }
}
