import { useEffect, useState } from "react";

const useViewportSize = (mobileBreakPoint = 960) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      return window.innerWidth < mobileBreakPoint;
    };

    setIsMobile(checkIsMobile());

    const handleResize = () => {
      setIsMobile(checkIsMobile());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobileBreakPoint]);

  return { isMobile };
};

export default useViewportSize;
