import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  paperRoot: {
    margin: '20px 0',
    borderTop: '3px solid #E16434',
    padding: 16
  },
  avatar: {
    padding: 2
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    fontFamily: 'Product Sans'
  },
  subheader: {
    fontWeight: 700,
    fontSize: 16,
    color: '#E16434'
  },
  loader: {
    position: 'absolute',
    left: '50%'
  },
  cardRoot: {
    cursor: 'pointer'
  }
}));

export default styles;
