import { Box } from "@material-ui/core";
import React, { useState } from "react";
import DefaultImg from "../../../assets/images/product-default-img.jpg";
import useStyles from "./styles";

const getFinalPrice = (product) => {
  // cut mrp and selling price and show only offer price
  if (parseFloat(product.offer_price) > 0) {
    return (
      <Box
        alignItems='center'
        justifyContent='space-between'
        width='100%'
      >
        <Box
          display='flex'
          justifyContent='space-between'
          width='100%'
          pt={1}
        >
          <Box
            fontSize={14}
            color='#666'
            style={{ textDecoration: "line-through" }}
          >
            MRP: &#x20b9;{product.mrp}
          </Box>
          <Box
            fontSize={14}
            color='#666'
            style={{ textDecoration: "line-through" }}
          >
            Selling Price: &#x20b9;{product.selling_price}
          </Box>
        </Box>
        <Box fontSize={18} pt={1} style={{ 'color': 'green' }}>
          Offer Price: &#x20b9;{product.offer_price}
        </Box>
      </Box >
    );
  }

  if (parseFloat(product.selling_price) > 0) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        width='100%'
        mt={1}
      >
        <Box
          fontSize={14}
          color='#666'
          style={{ textDecoration: "line-through" }}
        >
          MRP: &#x20b9;{product.mrp}
        </Box>
        <Box fontSize={18} style={{ 'color': 'green' }}>
          Selling Price: &#x20b9;{product.selling_price}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      width='100%'
      mt={1}
    >
      <Box fontSize={20} style={{ 'color': 'green' }}>
        Price: &#x20b9;{product.mrp}
      </Box>
    </Box>
  );
}

const ProductBox = ({ product, image, title, price, discountedPrice }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <Box width={"100%"} mb={2}>
      <Box className={classes.imageBox}>
        <img
          src={image}
          alt={title}
          className={classes.productImage}
          onLoad={handleImageLoad}
          loading="lazy"
        />
        <div className={`placeholder-img-div ${!loading ? classes.hidden : ''}`}>
          <img
            src={DefaultImg}
            alt='Default placeholder'
            className={`${classes.defaultImage} placeholder-img`}
            />
        </div>
      </Box>

      <Box>
        <Box className={classes.contentTop}>
          <Box fontSize={17} className={classes.productBoxTitle}>
            {title}
          </Box>

          <Box
            display='flex'
            alignItems='center'
            gridGap={10}
            color='#4f4f4f'
            mt={1}
          >
            <Box className={classes.pill}>Size: {product?.item_size}</Box>
            <Box className={classes.pill}>Color: {product?.item_color}</Box>
          </Box>
        </Box>

        <Box display='flex' alignItems='center'>
          {getFinalPrice(product)}
        </Box>

      </Box>
    </Box>
  );
};
export default ProductBox;
