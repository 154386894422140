import React, { useState, useEffect, useCallback } from "react";
import { Grid, TextField } from "@material-ui/core";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Box } from "@material-ui/core";
import CommonButton from "../Common/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showErrorSnackbar } from "../../actions/snackbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const ShippingDetails = ({ classes, handleAddressSubmit, isLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.auth.profile);
  const [customer, setCustomer] = useState({
    name: "",
    address: "",
    city: "",
    country: "India",
    state: "",
    pin_code: "",
    mobile_number: "",
  });

  useEffect(() => {
    if (profile) {
      setCustomer((currVal) => {
        return {
          ...currVal,
          address: profile.address ? profile.address : "",
          name: profile.name ? profile.name : "",
          mobile_number: profile.mobile_number ? profile.mobile_number : "",
          pin_code: profile.pin_code ? profile.pin_code : "",
          city: profile.city ? profile.city : "",
          email: profile.email ? profile.email : "",
          district: profile.district ? profile.district : "",
          state: profile.state ? profile.state : "",
        };
      });
    }
  }, [profile]);

  const onChange = (e) => {
    setCustomer({ ...customer, [e.target.name]: e.target.value });
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (customer.state === "") {
        dispatch(showErrorSnackbar("Please select your state"));
        return;
      }

      const data = {
        ...customer,
        customer_remarks: customer.customer_remarks,
      };

      handleAddressSubmit(data);
    },
    [handleAddressSubmit, customer, dispatch]
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PhoneInput
          value={"+91" + customer.mobile_number}
          onChange={(val) => setCustomer({ ...customer })}
          countryCodeEditable={false}
          country={"in"}
          masks={{ in: ".........." }}
          preferredCountries={["in"]}
          inputStyle={{ width: "100%", background: "none" }}
          containerStyle={{ marginTop: 32 }}
          specialLabel='Mobile'
          disabled
        />
        <TextField
          onChange={onChange}
          fullWidth
          variant='outlined'
          value={customer?.email || ""}
          name='email'
          label='Email'
          className={classes.shippingField}
        />
        <TextField
          required
          fullWidth
          variant='outlined'
          value={customer.name}
          name='name'
          onChange={onChange}
          label='Your Name'
          className={classes.shippingField}
        />
        <TextField
          required
          fullWidth
          variant='outlined'
          value={customer.address}
          name='address'
          onChange={onChange}
          label='Shipping Address'
          className={classes.shippingField}
        />
        <TextField
          required
          fullWidth
          variant='outlined'
          value={customer.city}
          name='city'
          onChange={onChange}
          label='Shipping City'
          className={classes.shippingField}
        />
        <Grid container spacing={3}>
          <Grid item sm={4} xs={12}>
            <CountryDropdown
              value={customer.country}
              onChange={(val) => setCustomer({ ...customer, country: val })}
              className={classes.regions}
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <RegionDropdown
              country={customer.country}
              value={customer.state || "Select a State"}
              onChange={(val) => setCustomer({ ...customer, state: val })}
              className={classes.regions}
              blankOptionLabel='Select State'
              defaultOptionLabel='Select State'
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <TextField
              required
              variant='outlined'
              label='Pin code'
              name='pin_code'
              placeholder='Your Pin code'
              value={customer.pin_code}
              inputProps={{ length: 6 }}
              onChange={onChange}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>

        <Box
          display='flex'
          justifyContent='space-between'
          flexWrap='wrap'
          mt={3}
          gridGap={15}
          className={classes.btnContainer}
        >
          <CommonButton
            rounded
            style={{ backgroundColor: "#1c5892" }}
            onClick={() => navigate("/cart")}
            classProp={classes.commonBtn}
            startIcon={<ArrowBackIcon />}
            customClass='shippingPageGoBackButton'
            gtmId='shippingPageGoBackButton'
          >
            Go Back
          </CommonButton>
          <CommonButton
            rounded
            type='submit'
            loading={isLoading}
            classProp={classes.commonBtn}
            startIcon={<ShoppingCartIcon />}
            customClass='shippingPageBuyNowButton'
            gtmId='shippingPageGoBackButton'
          >
            Buy Now
          </CommonButton>
        </Box>
      </form>
    </>
  );
};

export default ShippingDetails;
