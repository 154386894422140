import axios from "axios";
import { createAction } from 'redux-actions';
import { callApi, getAllReviews, getItemList, postReview } from '../api/items';
import {
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_SUCCESS,
  GET_REVIEWS_FAILURE,
  GET_REVIEWS_SUCCESS,
  SUBMIT_REVIEW_FAILURE,
  SUBMIT_REVIEW_SUCCESS
} from '../constants/actionTypes';
import { server_url, showError } from "../helpers/common";
import { showErrorSnackbar, showSuccessSnackbar } from './snackbar';

const getProductsSuccess = createAction(GET_PRODUCTS_SUCCESS);
const getProductsFailure = createAction(GET_PRODUCTS_FAILURE);

export function getProducts(queryParamsObj, perPage, page) {
  return dispatch => getItemList('products', queryParamsObj, perPage, page)
    .then((json) => dispatch(getProductsSuccess({
      ...json,
      similar_of: queryParamsObj.similar_of,
      productLabels: queryParamsObj.product_labels,
    })))
    .catch(error => dispatch(getProductsFailure(error)))
}
export function getProductList(queryParams) {
  return dispatch => callApi(queryParams)
    .then((json) => dispatch(getProductsSuccess({
      ...json,
    })))
    .catch(error => dispatch(getProductsFailure(error)))
}

const submitReviewSuccess = createAction(SUBMIT_REVIEW_SUCCESS);
const submitReviewFailure = createAction(SUBMIT_REVIEW_FAILURE);

export function submitNewReview(params, customer) {
  return dispatch => postReview(params)
    .then(data => {
      dispatch(showSuccessSnackbar('Your review has been submitted'));
      dispatch(submitReviewSuccess({ ...params, customer }));
      return true;
    })
    .catch(error => {
      dispatch(submitReviewFailure(error));
      dispatch(showErrorSnackbar(showError(error)));
      return false;
    });
}

export const fetchProductsByConditions = async (conditions) => {
  return axios.get(
    `${server_url}/products?${conditions}&per-page=200&page=1`
  );
};



const getReviewsSuccess = createAction(GET_REVIEWS_SUCCESS);
const getReviewsFailure = createAction(GET_REVIEWS_FAILURE);

export function getReviews(productId) {
  return dispatch => getAllReviews(productId)
    .then(data => dispatch(getReviewsSuccess(data)))
    .catch(error => dispatch(getReviewsFailure(error)));
}
