import React from 'react';
import { Box } from '@material-ui/core';
import Carousel from "react-multi-carousel";
import { Link } from 'react-router-dom';
import "react-multi-carousel/lib/styles.css";
import { useSelector } from 'react-redux';
import useStyles from './styles';
import { imageUrl } from '../../../helpers/common';

function ListingCarousel() {
  const classes = useStyles();
  const bannerList = useSelector(state => state.banners['Homepage Banners	']);

  const responsive = {
    default: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  return (
    <Box overflow="hidden">
      {bannerList?.length > 0 ? <Carousel
        draggable={true}
        keyBoardControl={true}
        responsive={responsive}
        infinite={true}
        partialVisible={"false"}
        autoPlay
        autoPlaySpeed={3000}
        arrows={true}
        showDots
      >
        {bannerList.map(banner => <Link to={`/${banner.url}`}>
          <Box className={classes.carouselItem}>
            <img src={imageUrl(banner.image, 'banners', '')} alt={banner?.name} />
            <Box className={classes.carouselItemContent} color="#fff" />
          </Box>
        </Link>)}
      </Carousel> : <></>
      }
    </Box >
  )
}

export default ListingCarousel;
