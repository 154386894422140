import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getFilterPath, getUrlParams } from "../../../helpers/common";
import FilterByColor from "./FilterByColor";
import FilterBySize from "./FilterBySize";
import { callApi } from "../../../api/items";

const ProductFilters = () => {
  const { categoryId, categoryName } = useParams();
  const navigate = useNavigate();

  const [availableFilterSizes, setAvailableFilterSizes] = useState(null);
  const [availableFilterColors, setAvailableFilterColors] = useState(null);

  const onFilterChange = (filterName, filterValue) => {
    let params = getUrlParams(filterName, filterValue);

    let pageNumber = 1;

    let path = getFilterPath(categoryId, categoryName, pageNumber, params);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    navigate(path, { replace: false });
  };

  const fetchFilterData = useCallback(
    async (field, setState) => {

      if (!categoryId) {
        return false
      }
      
      try {
        const res = await callApi(
          `ProductsSearch[category_id]=${categoryId}&fields=${field}&per-page=10000&page=1&group_by=${field}`
        );
        if (res?.data) {
          setState(res?.data);
        }
      } catch (error) {
        console.log("error::", error);
      }
    },
    [categoryId]
  );

  useEffect(() => {
    const fetchSizes = fetchFilterData("item_size", setAvailableFilterSizes);
    const fetchColors = fetchFilterData("item_color", setAvailableFilterColors);

    Promise.all([fetchSizes, fetchColors]).catch((error) =>
      console.log("error::", error)
    );
  }, [categoryId, fetchFilterData]);

  if (!categoryId) {
    return null;
  }

  return (
    <div>
      <FilterBySize
        onFilterChange={onFilterChange}
        availableFilters={availableFilterSizes}
      />
      <FilterByColor
        onFilterChange={onFilterChange}
        availableFilters={availableFilterColors}
      />
    </div>
  );
};

export default ProductFilters;
