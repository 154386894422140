import React, { useState } from "react";
import CommonButton from "../../CommonButton";
import useStyles from "../styles";
import useRazorpay from "react-razorpay";
import { showErrorSnackbar } from "../../../../actions/snackbar";
import { useDispatch } from "react-redux";
import { showError } from "../../../../helpers/common";
import BankIcon from '../../../../assets/images/bank-building.png'

const OnlinePayButton = ({
  handleCreateOrder,
  handleCreatePayment,
  paymentGateways,
  onPaymentSuccess,
  amountToPay
}) => {
  const classes = useStyles();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [RazorpayCheckout] = useRazorpay();
  const dispatch = useDispatch();

  const currentGateway = paymentGateways?.find(
    (item) => item?.internal_name === "razorpay"
  );

  const handleOpenRazorpay = (orderResponse) => {
    const apiKey =
      orderResponse?.settings?.api_details?.api_key ||
      orderResponse?.settings?.api_details?.key_id;

    const options = {
      key: apiKey,
      amount: orderResponse?.order.total_amount * 100,
      currency: orderResponse?.order?.currency_code ?? "INR",
      name: "Homeshop Fashion",
      order_id: orderResponse?.order?.payment_gateway_order_id,
      image: `https://homeshopfashion.com/backend/data/assets/1724178260-razorpay-logo.png`,
      theme: {
        color: "#E16434",
      },
      prefill: {
        name: orderResponse?.customer?.name,
        contact: orderResponse?.customer?.mobile_number,
      },
      notes: {
        address: orderResponse?.customer?.address,
      },
      handler: (response) =>
        onPaymentSuccess(orderResponse?.order?.order_id, response, orderResponse),
    };

    const rzr = new RazorpayCheckout(options);
    rzr.on("payment.failed", (errResponse) =>
      dispatch(showErrorSnackbar(showError(errResponse)))
    );
    rzr.open();
  };

  const handlePayOnline = async () => {
    setPaymentLoading(true);

    try {
      const response = await handleCreateOrder();
      if (response?.order) {
        let orderResponse = await handleCreatePayment(response, currentGateway);

        if (orderResponse?.order?.order_id) {
          handleOpenRazorpay(orderResponse);
        }
      }
    } catch (error) {
      console.log("error::", error);
    } finally {
      setPaymentLoading(false);
    }
  };

  return (
    <CommonButton
      classProp={`razarpayButton ${classes.proceedButton} ${classes.payOnlineBtn} payOnlineButton`}
      loading={paymentLoading}
      onClick={handlePayOnline}
      startIcon={<img src={BankIcon} alt="Pay online" />}
      gtmId="payOnlineButton"
    >
      Pay online - ₹{amountToPay}
    </CommonButton>
  );
};

export default OnlinePayButton;
