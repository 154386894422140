import { Box, Modal } from "@material-ui/core";
import React from "react";
import classes from "./styles.module.css";
import ClearIcon from "@material-ui/icons/Clear";
import CommonButton from "../CommonButton";
import moment from "moment";
import HomeShopLogo from "../../../assets/images/main-logo.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SuccessPopup = ({
  openSuccessPopup,
  setOpenSuccessPopup,
  orderResponse,
}) => {
  const profile = useSelector((state) => state.auth.profile);
  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "600px",
    height: "95%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 8,
    overflow: "hidden",
    outline: "none",
  };

  return (
    <Modal
      open={openSuccessPopup}
      onClose={() => setOpenSuccessPopup(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      style={{ zIndex: 99999 }}
    >
      <Box sx={style} overflow='auto'>
        <div className={classes.modal_header} style={{ height: 50 }}>
          <div></div>

          <button
            className='orderPlacedAndCross'
            data-gtm-id={"orderPlacedAndCross"}
            style={{ background: "none", border: "none" }}
          >
            <ClearIcon
              className={classes.closeIcon}
              onClick={() => setOpenSuccessPopup(false)}
            />
          </button>
        </div>
        <div className={`${classes.container} ${classes.scrollView}`}>
          <img
            src={HomeShopLogo}
            style={{ maxWidth: 150 }}
            alt='Home Shop Logo'
          />
          <h1 className={classes.successMessage}>Order Placed Successfully!</h1>
          <p style={{ margin: 0 }}>
            Your order has been confirmed. Thank you for shopping with us!
          </p>
          <div className={classes.details}>
            <h2 style={{ marginBottom: 8 }}>Order Details:</h2>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <td>
                    <strong>Order Number:</strong>
                  </td>
                  <td>{orderResponse?.order?.order_id || "#"}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Order Date:</strong>
                  </td>
                  <td>
                    {moment(orderResponse?.order?.order_date).format(
                      "DD MMM, YYYY"
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Order Amount:</strong>
                  </td>
                  <td>₹{orderResponse?.order?.total_amount || 0}</td>
                </tr>

                <tr>
                  <td>
                    <strong>Mobile:</strong>
                  </td>
                  <td>{orderResponse?.customer?.mobile_number}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <CommonButton
            classProp={`${classes.extraDiscountBtn} orderPlacedAndContinueButton`}
            onClick={() => navigate("/")}
            gtmId="orderPlacedAndContinueButton"
          >
            Continue Shopping
          </CommonButton>

          {profile?.id && (
            <CommonButton
              classProp={`${classes.extraDiscountBtn} orderPlacedAndViewOrderButton`}
              style={{ background: "#005286" }}
              onClick={() => navigate("/my-orders")}
              gtmId="orderPlacedAndViewOrderButton"
            >
              View Orders
            </CommonButton>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default SuccessPopup;
