import ajax from './ajax';

import { queryParams } from '../helpers/routeHelper';

export function getItemList(itemName, queryParamsObj, perPage, page) {
  return ajax(`/${itemName}${queryParams(itemName, queryParamsObj, perPage, page)}`);
}

export function getSimilarProducts(productId) {
  return ajax(`/products?ProductsSearch[similar_of]=${productId}&ProductsSearch[show_on_listing_page]=1`);
}

export function callApi(queryParams) {
  return ajax('/products?' + queryParams);
}

export function postReview(params) {
  return ajax('/products/reviews', { method: 'POST', data: params });
}

export function getAllReviews(productId) {
  return ajax(`/products/reviews?product_id=${productId}`)
}
