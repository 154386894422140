import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  mobileHeader: {
    display: "none",
    boxShadow: "0px 1px 10px rgba(0,0,0,.1)",
    borderBottom: "1px solid #eee",
    [theme.breakpoints.down("sm")]: { display: "block" },
  },
  appBar: {
    backgroundColor: "#fff !important",
    zIndex: theme.zIndex.drawer + 1,
    "& *": { fontFamily: "Product Sans" },
    "& a": { textDecoration: "none", color: "inherit" },
    marginBottom: 0,
    padding: "6px 8px 6px 14px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  logo: {
    "& img": {
      maxHeight: 55,
    },
  },
  badge: { backgroundColor: "#E16434", color: "#fff", top: -5, right: -5 },
  avatar: {
    color: "#E16434",
    cursor: "pointer",
    backgroundColor: "#fff",
    width: 35,
    height: 35,
    margin: 5,
    border: "2px solid #d6a65d",
  },
  link: {
    textDecoration: "none",
    minWidth: "100px",
    color: "#000",
    "&.active, &:hover": { color: "#E16434" },
  },
  searchInput: {
    padding: "6px 9px",
    backgroundColor: "#fff",
    border: "1px solid #b6b6b6",
    boxShadow: "none",
    // boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
    borderRadius: 24,
    display: "flex",
    gap: 5,
    margin: "0 20px 15px 13px",
    "& input": {
      fontFamily: "Product Sans",
      color: "#000",
      width: "100%",
    },
  },
  input: {
    width: "100%",
  },
  verticalNavItems: {
    padding: "16px 24px",
    textTransform: "uppercase",
    textAlign: "left",
    display: "block",
    borderBottom: "1px solid #eee",
    minWidth: 260
  },
  menuIconButton: {
    color: "#E16434 !important",
  },
}));
