import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainSec: {
    margin: "48px auto",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      paddingBottom: 80,
    },
    "@media(max-width: 465px)": {
      paddingBottom: 100,
    },
  },
  mainTitle: {
    marginBottom: 32,
    "@media(max-width: 465px)": {
      marginBottom: 17,
    },
  },
  btnContainer: {
    "@media(max-width: 960px)": {
      position: "absolute",
      bottom: 25,
      left: 0,
      right: 0,
      width: "100%",
      padding: '0 25px',
    },
    "@media(max-width: 465px)": {
      justifyContent: "center",
    },
  },
}));

export default useStyles;
