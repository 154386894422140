import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dash: { height: 5, width: 60, backgroundColor: "#E16434" },
  sliderContainer: {
    margin: "20px 32px",
    padding: "0 20px",
  },
  webSidebar: {
    "@media (max-width: 960px)": {
      display: "none",
    },
  },
  mobileSidebar: {
    display: "none",
    position: "relative",
    "@media (max-width: 960px)": {
      display: "block",
    },
  },
  mobHeadingContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 25,
    justifyContent: 'space-between',
    gap: 10
  },
  filterIcon: {
    width: 90,
    height: 36,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#E16434",
    color: '#fff',
    borderRadius: 20,
    gap: 5,
  },
  heading: {
    fontSize: 36,
    marginTop: 15,
    lineHeight: 1.2,
    "@media (max-width: 991px)": {
      fontSize: 26,
      paddingRight: 30,
    },
  },
  webHeading: {
    "@media (max-width: 960px)": {
      display: "none",
    },
  },
  mobileHeading: {
    display: "none",
    "@media (max-width: 960px)": {
      display: "block",
      margin: 0,
      fontSize: 23
    },
  },
  bottomBar: {
    // position: 'absolute',
    bottom: 20,
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#eee",
    padding: "10px 20px",
    borderTop: "1px solid #ccc",
    borderBottom: "1px solid #ccc",
    fontFamily: "Product Sans",
    color: "#555",
    fontWeight: 700,
    marginTop: 25,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      bottom: -40,
    },
  },
  bottomCarousel: {
    backgroundImage: `url(${require("../../assets/images/featured-product-bg.jpg")})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  leftSidebar: {
    "@media (max-width: 991px)": {},
  },
  mainGrid: {
    position: "relative",
    paddingLeft: 30,
    "@media (max-width: 991px)": {
      paddingLeft: 0,
    },
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  pagination: {
    "& button": { fontFamily: "Product Sans", fontWeight: 700, color: "#555" },
  },
  filterBar: {
    backgroundColor: "#f2f2f2",
    padding: "8px 15px",
    marginBottom: 25,
    display: "flex",
    alignItems: "center",
    gap: 10,
    cursor: "pointer",
  },
  filterPillWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    flexWrap: "wrap",
  },
  filterPill: {
    background: "#fff",
    padding: "6px 10px 4px",
    fontSize: 13,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
}));

export default useStyles;
