import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import {KeyboardArrowUp, KeyboardArrowDown} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  dash: {
    height: 5,
    width: 60,
    backgroundColor: "#E16434",
    display: "flex",
    "&.center": {
      margin: "auto",
    },
    "&.right": {
      justifyContent: "flex-end",
    },
  },
  heading: {
    "@media (max-width: 991px)": {
      fontSize: '20px !important',
    },
  }
}));

const CustomHeading = ({
  text,
  fontSize = 24,
  align = "left",
  showRightIcon,
  isCollaped,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box
      display='flex'
      style={{ cursor: showRightIcon ? "pointer" : "default" }}
      alignItems='center'
      justifyContent='space-between'
      pr={3.5}
      {...props}
    >
      <Box>
        <Box fontSize={fontSize} className={classes.heading} mb={1} mt={3} textAlign={align}>
          {text}
        </Box>
        <Box
          className={classnames(classes.dash, align === "center" && "center")}
        />
      </Box>

      {showRightIcon && (
        <Box mt={2.5} className={classes.iconBox}>
          {isCollaped ? (
            <KeyboardArrowDown style={{ fontSize: 26 }} />
          ) : (
            <KeyboardArrowUp style={{ fontSize: 26 }} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default CustomHeading;
