import React from "react";
import { Box, Modal } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import classes from "./styles.module.css";
import DiscountTagIcon from "../../../assets/images/discount-tag-icon.png";
import CommonButton from "../CommonButton";

const ExtraDiscountPopup = ({
  openModal,
  setOpenOfferPopup,
  handleParentClose,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "360px",
    height: "100%",
    maxHeight: "510px",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 8,
    overflow: "hidden",
    outline: "none",
  };

  const handleClose = () => {
    setOpenOfferPopup(false);
  };


  const handleForceClose = () => {
    setOpenOfferPopup(false);
    handleParentClose();
  }

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      style={{ zIndex: 99999 }}
    >
      <Box sx={style}>
        <Box
          className={classes.modal_header}
          style={{ border: "none !important", padding: "8px 10px" }}
        >
          <div></div>
          <ClearIcon className={classes.closeIcon} onClick={handleClose} />
        </Box>

        <Box
          className={classes.modal_body}
          textAlign='center'
          style={{ overflow: "auto", maxHeight: 480 }}
        >
          <Box fontSize={22} fontWeight={600}>
            Wait!
          </Box>

          <Box fontSize={16}>We have an offer for you!</Box>

          <Box fontSize={16} fontWeight={600} mt={2} mx={5} mb={1.5}>
            GET AN EXTRA DISCOUNT ON YOUR ORDER:
          </Box>

          <Box position='relative' className={classes.discountTag}>
            <img src={DiscountTagIcon} alt='discount tag' />

            <Box className={classes.discountNum}>20%</Box>
          </Box>

          <Box fontSize={16} mb={2.3} mt={2}>
            Do you want to complete your order?
          </Box>

          <CommonButton
            classProp={`${classes.extraDiscountBtn} ${classes.noThanksBtn}`}
            onClick={handleForceClose}
          >
            No thank you
          </CommonButton>

          <CommonButton
            classProp={classes.extraDiscountBtn}
            onClick={handleForceClose}
          >
            Get Extra 20% OFF
          </CommonButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ExtraDiscountPopup;
