import { Box, CircularProgress } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  callOrdersCheckoutApi,
  getPaymentGateways,
} from "../../../../actions/orders";
import { showErrorSnackbar } from "../../../../actions/snackbar";
import {
  authKey_guest_local_storage_key,
  authKey_local_storage_key,
  showError,
  userInfo_guest_local_storage_key,
} from "../../../../helpers/common";
import CodButton from "./CodButton";
import OnlinePayButton from "./OnlinePayButton";
import { createPayment, verifyPayment } from "../../../../api/orders";

const PaymentButtons = ({
  appliedCoupon,
  customer,
  handleValidation,
  amountToPay,
  handleCheckoutSuccess,
  isGuestLoading,
}) => {
  const cartProducts = useSelector((state) => state.cart.buy_now_cart_data);
  const dispatch = useDispatch();

  const [paymentGateways, setPaymentGateways] = useState([]);
  const [isGatewayLoaded, setIsGatewayLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getPaymentGateways()
      .then(
        (response) => {
          setPaymentGateways(response?.data);
        },
        (err) => {
          if (err?.response?.data?.message) {
            showError(err.response.data.message);
          } else {
            showError(err.message);
          }
        }
      )
      .finally(() => {
        setIsGatewayLoaded(true);
      });
  }, []);

  const handleCreateOrder = useCallback(async () => {
    if (!handleValidation()) {
      return;
    }

    const authToken =
      localStorage.getItem(authKey_local_storage_key) ||
      localStorage.getItem(authKey_guest_local_storage_key);

    if (!authToken) {
      dispatch(
        showErrorSnackbar(
          "Failed to load token. Please re-enter your mobile number and try again."
        )
      );
      return;
    }

    const items = cartProducts?.map((d) => ({
      item_id: d.id,
      quantity: d.qty,
    }));

    localStorage.setItem(
      userInfo_guest_local_storage_key,
      JSON.stringify(customer)
    );

    try {
      const response = await callOrdersCheckoutApi(dispatch, authToken, {
        customer,
        items,
        coupon_code: appliedCoupon,
      });

      if (response.status === 200 && response.data) {
        return response.data;
      } else {
        dispatch(showErrorSnackbar("Something went wrong. Please try again"));
        return null;
      }
    } catch (error) {
      let errorMsg = error.message;
      if (error?.response?.data?.message) {
        errorMsg = error.response.data.message;
      }
      dispatch(showErrorSnackbar(errorMsg));
      return null;
    }
  }, [appliedCoupon, cartProducts, customer, dispatch, handleValidation]);

  const handleCreatePayment = useCallback(
    async (checkoutResponse, currentGateway) => {
      try {
        let response = await createPayment({
          id: checkoutResponse?.order?.id,
          payment_gateway: currentGateway?.internal_name,
          payment_gateway_environment: currentGateway?.environment,
        });

        return response.data;
      } catch (error) {
        dispatch(showErrorSnackbar(showError(error)));
        console.log("payment error::", error);
        return error;
      }
    },
    [dispatch]
  );

  const onPaymentSuccess = async (orderId, response, orderResponse) => {
    try {
      let verificationResponse = await verifyPayment({
        order_id: orderId,
        payload: response,
      });

      if (verificationResponse?.data?.payment_status === "Failed") {
        throw new Error(
          verificationResponse?.data?.payment_verification_error_message
        );
      }

      handleCheckoutSuccess(orderResponse);
    } catch (error) {
      console.log(error);
      dispatch(showErrorSnackbar(showError(error)));
    }
  };

  if (!isGatewayLoaded || isGuestLoading) {
    return (
      <div style={{ textAlign: "center", marginTop: 30 }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box pt={2} mb={3}>
      <h4>Payment Options</h4>
      <CodButton
        handleCreateOrder={handleCreateOrder}
        handleCreatePayment={handleCreatePayment}
        paymentGateways={paymentGateways}
        onPaymentSuccess={onPaymentSuccess}
        amountToPay={amountToPay}
      />

      <OnlinePayButton
        handleCreateOrder={handleCreateOrder}
        handleCreatePayment={handleCreatePayment}
        paymentGateways={paymentGateways}
        onPaymentSuccess={onPaymentSuccess}
        amountToPay={amountToPay}
      />
    </Box>
  );
};

export default PaymentButtons;
