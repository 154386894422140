import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#E16434',
    padding: '8px 15px',
    color: '#fff',
    textTransform: 'uppercase',
    minWidth: 80,
    minHeight: 40,
    border: 'none',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    transition: 'all 0.3s ease-in-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    "&:hover": {
      backgroundColor: '#d5d5d5 !important'
    },
    '& svg': {
      fontSize: 20,
    }
  },
  disabledButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
    boxShadow: 'none !important',
    cursor: 'default !important',
  },
  outlineButton: {
    backgroundColor: 'transparent',
    color: '#000',
    borderColor: '#E16434',
  },  
  wrapper: {
    margin: 0,
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default useStyles;
