import { Box, Grid, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { callOrdersCheckoutApi } from "../../actions/orders";
import { showErrorSnackbar } from "../../actions/snackbar";
import { authKey_local_storage_key } from "../../helpers/common";
import MetaTags from "../Common/MetaTags";
import ShippingDetailsForm from "./ShippingDetailsForm";
import ShippingProductDisplay from "./ShippingProductDisplay";
import useStyles from "./styles";

export default function Shipping() {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [appliedCouponValue, setAppliedCouponValue] = useState("");

  useEffect(() => {
    const authToken = localStorage.getItem(authKey_local_storage_key);
    setToken(authToken);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("hsCartProducts"));
    if (items && Array.isArray(items)) setProducts(items);
  }, []);

  const handleAddressSubmit = useCallback(
    (customer) => {
      const items = products.map((d) => {
        return {
          item_id: d.id,
          quantity: d.qty,
        };
      });

      let customer_remarks = location?.state?.remark;

      setLoading(true);
      callOrdersCheckoutApi(dispatch, token, {
        customer,
        items,
        customer_remarks,
        coupon_code: appliedCouponValue,
      })
        .then((response) => {
          if (response.status === 200 && response.data) {
            navigate(`/checkout/${response.data.order.id}`, {
              state: { order: response.data },
            });
          } else {
            dispatch(
              showErrorSnackbar("Something went wrong. Please try again")
            );
          }
        })
        .catch((error) => {
          let errorMsg = error.message;
          if (error?.response?.data?.message) {
            errorMsg = error.response.data.message;
          }
          dispatch(showErrorSnackbar(errorMsg));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      products,
      location?.state?.remark,
      dispatch,
      token,
      appliedCouponValue,
      navigate,
    ]
  );

  return (
    <Box maxWidth={1200} p={3} className={classes.mainSec} overflow='hidden'>
      <MetaTags title='Shipping Details' description='' keywords=''></MetaTags>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <Typography
            variant='h5'
            color='textSecondary'
            style={{ marginBottom: 32 }}
          >
            Shipping Details
          </Typography>

          <ShippingDetailsForm
            isLoading={isLoading}
            handleAddressSubmit={handleAddressSubmit}
            classes={classes}
          />
        </Grid>

        <ShippingProductDisplay
          appliedCouponValue={appliedCouponValue}
          setAppliedCouponValue={setAppliedCouponValue}
        />
      </Grid>
    </Box>
  );
}
