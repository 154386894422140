import { Box, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import classes from "./styles.module.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ProductSummary from "./ProductSummary";
import ShippingForm from "./ShippingForm";
import ExtraDiscountPopup from "./ExtraDiscountPopup";
import SuccessPopup from "./SuccessPopup";
import { authKey_guest_local_storage_key } from "../../../helpers/common";

const CheckoutPopup = ({ openModal, handleClose }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "600px",
    height: "95%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 8,
    overflow: "hidden",
    outline: "none",
  };

  const [openOfferPopup, setOpenOfferPopup] = useState(false);
  const [appliedCouponValue, setAppliedCouponValue] = useState(null);
  const [amountToPay, setAmountToPay] = useState(0);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [orderResponse, setOrderResponse] = useState(false);

  const handleClosePopup = () => {
    // setOpenOfferPopup(true);
    handleClose();
  };

  const handleCheckoutSuccess = (response) => {
    handleClose();
    setOrderResponse(response);
    setOpenSuccessPopup(true);
  };

  useEffect(() => {
    if (openModal) {
      localStorage.removeItem(authKey_guest_local_storage_key)
    } 
  }, [openModal])

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClosePopup}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ zIndex: 99998 }}
      >
        <Box sx={style}>
          <div className={classes.modal_header}>
            <div className={classes.backButton} onClick={handleClosePopup}>
              <ArrowBackIcon />
              <h4>Back to shopping</h4>
            </div>

            <ClearIcon
              className={`${classes.closeIcon} checkoutPopupCloseButton`}
              onClick={handleClosePopup}
              data-gtm-id={"checkoutPopupCloseButton"}
            />
          </div>

          <div className={classes.modal_body} >
            <div className={classes.scrollView}>
              <ProductSummary
                appliedCouponValue={appliedCouponValue}
                setAppliedCouponValue={setAppliedCouponValue}
                setAmountToPay={setAmountToPay}
              />

              <div style={{ borderBottom: "1px solid #e1e1e1" }}></div>

              <ShippingForm
                appliedCoupon={appliedCouponValue}
                amountToPay={amountToPay}
                handleCheckoutSuccess={handleCheckoutSuccess}
              />
            </div>
          </div>
        </Box>
      </Modal>

      <ExtraDiscountPopup
        openModal={openOfferPopup}
        setOpenOfferPopup={setOpenOfferPopup}
        handleParentClose={handleClose}
      />

      <SuccessPopup
        openSuccessPopup={openSuccessPopup}
        setOpenSuccessPopup={setOpenSuccessPopup}
        orderResponse={orderResponse}
      />
    </div>
  );
};

export default CheckoutPopup;
