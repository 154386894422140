import {
  Box,
  IconButton,
  Paper,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import useStyles from "./mobileStyles";
import { useSelector } from "react-redux";
import { imageUrl } from "../../../helpers/common";

const MobileMenuDrawer = ({ logo }) => {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(false);
  const navMenus = useSelector((state) => state.menus.topMenu);

  const MenuButton = () => {
    return (
      <IconButton
        edge='start'
        className={classes.menuButton + " " + classes.menuIconButton}
        aria-label='menu'
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? (
          <CloseIcon fontSize={"large"} />
        ) : (
          <MenuIcon fontSize={"large"} />
        )}
      </IconButton>
    );
  };

  return (
    <>
      <MenuButton />

      <SwipeableDrawer
        anchor={"left"}
        open={collapsed}
        onClose={() => setCollapsed(false)}
        onOpen={() => setCollapsed(true)}
        style={{ zIndex: 999999999 }}
      >
        <Box mt={1} mr={2} mb={2} ml={1.8}>
          <Link to='/' className={classes.logo} 
          onClick={() => setCollapsed(false)}
          >
            <img
              src={`${imageUrl(logo?.file_name, "assets", "")}`}
              alt='HomeShop Fashion Logo'
            />
          </Link>
        </Box>
        <Paper
          elevation={0}
          square
          style={{ height: "calc(100vh - 140px)", overflowY: "auto" }}
        >
          {navMenus?.map((menu) => (
            <Link
              key={menu.id}
              to={menu.url}
              className={classNames(classes.verticalNavItems, classes.link)}
              onClick={() => setCollapsed(false)}
            >
              <Typography variant='body2' className={`${classes.mobNavItems}`}>
                {menu.name}
              </Typography>
            </Link>
          ))}

          <div style={{ height: 50 }} />
        </Paper>
      </SwipeableDrawer>
    </>
  );
};

export default MobileMenuDrawer;
