import React, { useState } from 'react';
import { Box, Grid, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import 'react-phone-input-2/lib/material.css';
import useStyles from './styles'
import { updateProfile } from '../../actions/customer';
import { showErrorSnackbar, showSuccessSnackbar } from '../../actions/snackbar';
import CommonButton from '../Common/CommonButton';
import { authKey_local_storage_key, userInfo_local_storage_key } from '../../helpers/common';
import MetaTags from '../Common/MetaTags';


export default function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const customer = useSelector(state => state.auth.profile);
  const token = localStorage.getItem(authKey_local_storage_key);
  const [profile, setProfile] = useState(customer || {
    name: '',
    email: '',
    gender: '',
    // dob: new Date(),
    city: '' ,
    pin_code: '',
    address: ''
  });

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    return dispatch(updateProfile({ ...profile, id: customer.id })).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        dispatch(showSuccessSnackbar('Profile Updated successfully !'));
        const customer = res.data;
        localStorage.setItem(userInfo_local_storage_key, JSON.stringify({ customer, token }));
      }
      else dispatch(showErrorSnackbar('Sorry! Profile cannot be updated !'))
    });
  }

  const onValueChange = (event) => {
    setProfile({ ...profile, [event.target.name]: event.target.value })
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={8} px={3}>
      <MetaTags
        title="My Profile"
        description=""
        keywords=""
      ></MetaTags>
      
      <Box fontSize={24} fontWeight={700} color="#666" mt={5} mb={2}>
        UPDATE PROFILE
      </Box>
      <form onSubmit={onSubmit}>
        <Box width={480} px={2} className="input-sec">
          <Grid container alignItems="center">
            <Grid item sm={3} xs={12} >
              <Box mr={1} textAlign="left">Name</Box>
            </Grid>
            <Grid item sm={9} xs={12}  className={classes.input}>
              <TextField
                fullWidth
                value={profile.name}
                variant="outlined"
                name="name"
                onChange={onValueChange}
                placeholder="Name"
              />
            </Grid>
          </Grid>
        </Box>

        <Box width={480} px={2} className="input-sec" mt={2}>
          <Grid container alignItems="center">
            <Grid item sm={3} xs={12} >
              <Box mr={1} textAlign="left">Mobile Number</Box>
            </Grid>
            <Grid item sm={9} xs={12} className={classes.input} style={{cursor: 'not-allowed'}}>
              <TextField
                fullWidth
                placeholder="mobile number"
                variant="outlined"
                type="text"
                name="mobile_number"
                value={profile.mobile_number || ''}
                inputProps={{ minLength: 6 }}
                style={{cursor: 'pointer'}}
                // onChange={onValueChange}
                disabled
              />
            </Grid>
          </Grid>
        </Box>

        <Box width={480} px={2} className="input-sec" mt={2}>
          <Grid container alignItems="center">
            <Grid item sm={3} xs={12} >
              <Box mr={1} textAlign="left">Email</Box>
            </Grid>
            <Grid item sm={9} xs={12} className={classes.input}>
              <TextField
                fullWidth
                placeholder="Email"
                variant="outlined"
                type="email"
                name="email"
                value={profile.email || ''}
                inputProps={{ minLength: 6 }}
                onChange={onValueChange}
              />
            </Grid>
          </Grid>
        </Box>

        {/* <Box width={480} px={2} className="input-sec" mt={2}>
          <Grid container alignItems="center">
            <Grid item sm={3} xs={12} >
              <Box mr={1} textAlign="left">Gender</Box>
            </Grid>
            <Grid item sm={9} xs={12} className={classes.input}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={profile.gender}
                  onChange={onValueChange}
                  label="Gender"
                  name="gender"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'male'}>Male</MenuItem>
                  <MenuItem value={'female'}>Female</MenuItem>
                  <MenuItem value={'others'}>Others</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box> */}

        {/* <Box width={480} px={2} className="input-sec" mt={2}>
          <Grid container alignItems="center">
            <Grid item sm={3} xs={12} >
              <Box mr={1} textAlign="left">Dob</Box>
            </Grid>
            <Grid item sm={9} xs={12} className={classes.input}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  label="Date of Birth"
                  format="dd/MM/yyyy"
                  value={profile.dob}
                  name="dob"
                  InputAdornmentProps={{ position: "end" }}
                  onChange={(date) => setProfile({ ...profile, dob: date })}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Box> */}

        <Box width={480} px={2} className="input-sec" mt={2}>
          <Grid container alignItems="center">
            <Grid item sm={3} xs={12} >
              <Box mr={1} textAlign="left">Address</Box>
            </Grid>
            <Grid item sm={9} xs={12} className={classes.input}>
              <TextField
                fullWidth
                value={profile.address}
                variant="outlined"
                name="address"
                multiline
                rows={4}
                onChange={onValueChange}
                placeholder="Address"
              />
            </Grid>
          </Grid>
        </Box>

        <Box width={480} px={2} className="input-sec" mt={2}>
          <Grid container alignItems="center">
            <Grid item sm={3} xs={12} >
              <Box mr={1} textAlign="left">City</Box>
            </Grid>
            <Grid item sm={9} xs={12} className={classes.input}>
              <TextField
                fullWidth
                value={profile.city}
                variant="outlined"
                name="city"
                onChange={onValueChange}
                placeholder="City"
              />
            </Grid>
          </Grid>
        </Box>

        <Box width={480} px={2} className="input-sec" mt={2}>
          <Grid container alignItems="center">
            <Grid item sm={3} xs={12} >
              <Box mr={1} textAlign="left">Pin Code</Box>
            </Grid>
            <Grid item sm={9} xs={12} className={classes.input}>

              <TextField
                fullWidth
                placeholder="Pin Code"
                variant="outlined"
                type="number"
                value={profile.pin_code}
                name="pin_code"
                inputProps={{ length: 6 }}
                onChange={onValueChange}
              />
            </Grid>
          </Grid>
        </Box>

        {/* <Box display="flex" justifyContent="center" mt={3}>
          <Button type="submit" variant="contained" color="primary" loading={loading}>Save Information</Button>
        </Box> */}
        <Box display="flex" justifyContent="center" mt={2}>
          <CommonButton rounded width={150} type="submit" loading={loading}>Save Information</CommonButton>
        </Box>
      </form >
    </Box >
  );
}
