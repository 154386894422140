import {
  Box,
  Collapse,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect } from "react";
import CustomHeading from "../../Common/CustomHeadings";
import useStyles from "./styles";
import {
  getAppliedFilterByName,
  getSettingByKey,
  toKeyValues,
} from "../../../helpers/common";
import { useSelector } from "react-redux";

const FilterBySize = ({ onFilterChange, availableFilters }) => {
  const settings = useSelector((state) => state.contents.frontendData);
  const filtersSizesStr = getSettingByKey(settings, "filter_sizes")?.value;
  const sizesData = toKeyValues(filtersSizesStr);
  const filterName = "size";
  const classes = useStyles();

  const filteredSizesData = sizesData?.filter((size) =>
    availableFilters?.find(
      (item) => item?.item_size?.toLowerCase() === size?.key?.toLowerCase()
    )
  );

  const appliedFilterValue = getAppliedFilterByName(filterName);

  const [value, setValue] = React.useState(appliedFilterValue);

  useEffect(() => {
    setValue(appliedFilterValue);
  }, [appliedFilterValue]);

  const handleChange = (event) => {
    const value = event.target.value;

    onFilterChange(filterName, value);

    setValue(event.target.value);
  };

  const [open, setOpen] = React.useState(false);

  if (filteredSizesData?.length === 0) {
    return null;
  }

  return (
    <div>
      <CustomHeading
        text='Filter by Size'
        showRightIcon={true}
        isCollaped={!open}
        onClick={() => setOpen(!open)}
      />

      <Collapse in={open}>
        <Box mt={3} pl={0.5}>
          <RadioGroup
            aria-label={`${filterName}`}
            name={`${filterName}-1`}
            value={value}
            onChange={handleChange}
            className={classes.radioGroup}
          >
            {filteredSizesData?.map((item, index) => (
              <FormControlLabel
                key={`${filterName}-${index}`}
                value={item?.key}
                control={
                  <Radio color='primary' size='small' className={classes.radio} />
                }
                label={item?.value}
              />
            ))}
          </RadioGroup>
        </Box>
      </Collapse>
    </div>
  );
};

export default FilterBySize;
