import React, { useState } from "react";
import classes from "./styles.module.css";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { Box, CircularProgress, Modal } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { imageUrl } from "../../../helpers/common";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../actions/snackbar";
import moment from "moment";
import { getCouponDiscount } from "../../../api/coupons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const ApplyCoupons = ({
  appliedCouponValue,
  setAppliedCouponValue,
  cartProducts,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [couponValue, setCouponValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
    setErrorMsg("");
    setCouponValue("");
  };
  const dispatch = useDispatch();

  const couponList = useSelector((state) => state.coupons.data);

  const cartDiscount = localStorage.getItem("hs_coupon_discount");
  const parsedCartDiscount = JSON.parse(cartDiscount);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "500px",
    height: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 8,
    overflow: "hidden",
    outline: "none",
  };

  const handleApplyCoupon = async (couponCode) => {
    const code = couponCode || couponValue;

    if (!code) {
      setErrorMsg("Please enter coupon code!");
      return;
    }

    const items = cartProducts.map((d) => {
      return {
        item_id: d?.id,
        quantity: parseInt(d?.qty),
      };
    });

    // checking is coupon code already applied
    if (
      appliedCouponValue &&
      appliedCouponValue?.toLocaleLowerCase() === code?.toLocaleLowerCase()
    ) {
      setErrorMsg("Coupon is already applied.");
      return;
    }

    setErrorMsg("");
    const payload = {
      items: items,
      currency_code: "INR",
      coupon_code: code,
    };

    function checkCouponApply(items) {
      let totalDiscount = 0;

      for (const item of items) {
        if (item.discount > 0) {
          totalDiscount += item.discount;
        }
      }

      if (totalDiscount === 0) {
        throw new Error(
          "Sorry! This coupon is not applicable to any of the selected products."
        );
      }
    }

    try {
      setLoading(true);
      const response = await getCouponDiscount(payload);

      if (response?.data) {
        await checkCouponApply(response?.data);

        const couponDataResponse = {
          applied_coupon_code: code,
          data: response?.data,
        };

        localStorage.setItem(
          "hs_coupon_discount",
          JSON.stringify(couponDataResponse)
        );

        dispatch(showSuccessSnackbar("Coupon applied successfully."));
        setAppliedCouponValue(code);
        setOpenModal(false);
      }
    } catch (error) {
      dispatch(
        showErrorSnackbar(error?.response?.data?.message || error?.message)
      );
      setErrorMsg(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClearCoupon = () => {
    setAppliedCouponValue("");
    localStorage.removeItem("hs_coupon_discount");
  };

  const renderInputSec = () => {
    return (
      <>
        <div className={classes.input_sec}>
          <LocalOfferIcon
            className={`${classes.input_button} ${classes.offerIcon}`}
          />
          <input
            type='text'
            className={classes.modal_input}
            placeholder='Enter coupon code'
            onChange={(e) => {
              setErrorMsg("");
              setCouponValue(e.target.value);
            }}
            value={couponValue}
          />
          {couponValue && (
            <button
              className={`${classes.input_button} applyCouponButtonOnForm`}
              onClick={() => handleApplyCoupon()}
              data-gtm-id={"applyCouponButtonOnForm"}
            >
              Apply
            </button>
          )}
        </div>
        {errorMsg && couponValue && (
          <p className={classes.errorMsg}>{errorMsg}</p>
        )}
      </>
    );
  };

  return (
    <>
      {appliedCouponValue ? (
        <div
          className={classes.main}
          style={{
            background: "#eee",
            padding: "0 10px",
            borderRadius: 8,
          }}
        >
          <div className={classes.left_col}>
            <LocalOfferIcon className={classes.labelIcon} />
            <h4 className={classes.applied}>
              Coupon "{appliedCouponValue}" Applied
            </h4>
          </div>

          <ClearIcon
            className={classes.closeIcon}
            onClick={handleClearCoupon}
          />
        </div>
      ) : (
        <div className={classes.couponInputSec}>
          {renderInputSec()}

          <div className={classes.viewCouponsSec}>
            <h5>
              {couponList?.length} coupon{couponList?.length === 1 ? "" : "s"}{" "}
              available
            </h5>

            <button
              onClick={() => setOpenModal(true)}
              className='viewCouponsButton'
              data-gtm-id={"viewCouponsButton"}
            >
              View coupons <ChevronRightIcon />
            </button>
          </div>
        </div>
      )}

      <div style={{ borderBottom: "1px solid #e1e1e1", marginTop: 18 }}></div>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ zIndex: 99999 }}
      >
        <Box sx={style}>
          {loading && (
            <div className={classes.loader}>
              <CircularProgress size={26} color='inherit' />
            </div>
          )}

          <div className={classes.modal_header}>
            <h3>Apply Coupon</h3>

            <ClearIcon
              className={`${classes.closeIcon} couponPopupCloseButton`}
              onClick={handleClose}
              data-gtm-id={"couponPopupCloseButton"}
            />
          </div>

          <div className={classes.modal_body}>
            {renderInputSec()}

            <div className={classes.scrollView}>
              {couponList?.map((item) => (
                <div className={classes.coupon_item} key={item?.id}>
                  <div className={classes.coupon_image}>
                    <img
                      src={imageUrl(item?.image, "coupons", "120x120-")}
                      alt=''
                    />
                  </div>

                  <div className={classes.coupon_info}>
                    <button
                      className={`${classes.coupon_code} ${
                        classes[
                          item?.coupon_code ===
                          parsedCartDiscount?.applied_coupon_code
                            ? "active_coupon_code"
                            : ""
                        ]
                      } applyCouponButton`}
                      title='Apply coupon'
                      style={{ background: "none" }}
                      onClick={() => handleApplyCoupon(item?.coupon_code)}
                    >
                      {item?.coupon_code}
                    </button>
                    {item?.coupon_code ===
                      parsedCartDiscount?.applied_coupon_code && (
                      <span className={classes.appliedMark}>
                        <CheckIcon className={classes.checkIcon} /> Applied
                      </span>
                    )}

                    <div className={classes.title}>{item?.title}</div>
                    <div className={classes.description}>
                      {item?.description}
                    </div>

                    <div className={classes.description}>
                      Expires on:{" "}
                      {moment(item?.end_date).format("Do MMM, YYYY")}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ApplyCoupons;
