import React, { useCallback, useState } from "react";
import WebHeader from "./WebHeader";
import MobileHeader from "./MobileHeader";
import { useDispatch } from "react-redux";
import { logout } from "../../../actions/customer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../actions/snackbar";
import { redirect_path_after_login } from "../../../helpers/common";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {pathname} = useLocation()

  let { keyword } = useParams();
  const [searchKeyword, setSearchKeyword] = useState(keyword);

  const onLogout = useCallback(() => {
    localStorage.setItem(redirect_path_after_login, pathname)

    dispatch(logout());
    navigate("/login");
    dispatch(showSuccessSnackbar("You are logged out!"));
  }, [dispatch, navigate, pathname]);

  const handleSearch = useCallback(
    (e) => {
      if (!searchKeyword) {
        dispatch(showErrorSnackbar("Please enter any keyword"));
        return false;
      } else {
        navigate("/search/" + searchKeyword);
      }
    },
    [searchKeyword, dispatch, navigate]
  );

  const onSearchInput = (e) => {
    if (e.key === "Enter") handleSearch();
    setSearchKeyword(e.target.value);
  };

  return (
    <div>
      <WebHeader
        onLogout={onLogout}
        searchKeyword={searchKeyword}
        onSearchInput={onSearchInput}
        setSearchKeyword={setSearchKeyword}
      />

      <MobileHeader
        onLogout={onLogout}
        searchKeyword={searchKeyword}
        onSearchInput={onSearchInput}
        setSearchKeyword={setSearchKeyword}
      />
    </div>
  );
};

export default Header;
