import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  items: {
    width: "100%",
  },
  images: {
    width: "100%",
    objectFit: "cover",
    border: "1px solid #aaa",
    borderRadius: 6,
  },
  removeBtn: {
    cursor: "pointer",
    color: "#E16434",
    marginLeft: 5,
  },
  appliedCoupon: {
    color: "#1eab5c",
    textTransform: "uppercase",
    fontWeight: 600,
  },
  btnContainer: {
    "@media(max-width: 960px)": {
      position: "absolute",
      bottom: 25,
      left: 0,
      right: 0,
      width: "100%",
      padding: '0 25px',
    },
    "@media(max-width: 465px)": {
      justifyContent: "center",
    },
  },
  commonBtn: {
    width: 200,
    "@media(max-width: 465px)": {
      width: 270,
      margin: "auto",
    },
  },
  prodBox: {
    padding: "5px 0",
    display: "flex",
    borderBottom: "1px solid #e1e1e1",
    marginBottom: 5
  },
  prodImgBox: {
    width: 80,
    borderRadius: 5,
    border: "1px solid transparent",
    "@media(max-width: 465px)": {
      width: 55,
    },
  },
  prodName: {
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0.5,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    '-webkitLineClamp': 3,
    '-webkitBoxOrient': 'vertical',
    textOverflow: 'ellipsis',
    "@media(max-width: 465px)": {
      fontSize: 15,
    },
  },
  prodAmount: {
    fontSize: 20,
    fontWeight: '700',
    margin: '0 10px',
    "@media(max-width: 465px)": {
      fontSize: 18,
    },
  },
  pill: {
    background: "#eee",
    borderRadius: 20,
    padding: "4px 10px",
    display: "flex",
    alignItems: "center",
    marginBottom: 0,
    fontSize: 11,
    fontWeight: 600,
    letterSpacing: 0.6,
    color: '#353535',
    "@media(max-width: 500px)": {
      fontSize: 11,
    },
  },
  shippingField: {
    margin: "16px 0",
  },
  regions: {
    fontFamily: "Product Sans",
    height: 56,
    marginRight: 32,
    width: "100%",
    color: "#000000de",
    fontSize: 16,
    borderRadius: 5,
    borderColor: "#c0c0c0",
    background: "none",
  },
  proceedButton: {
    width: "100%",
    // margin: '20px 0 20px',
    borderRadius: '30px !important',
    padding: '12px 0 !important',
    fontSize: 15,
    minHeight: "45px",
    '& img': {
      width: 23,
      filter:'invert(1)'
    }
  },
  payOnlineBtn: {
    background: "#005286 !important",
    marginTop: 15,
    fontSize: 15,
    padding: '12px 0 !important',
    borderRadius: '30px !important',
    '& img': {
      width: 18,
    }
  }
}));

export default useStyles;
